<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Utilisateurs</v-card-title>

      <v-card-text>
        <div class="global-actions">
          <router-link :to="{ name: 'userCreate' }">
            <v-btn
              small
              color="primary"
              class="mt-5"
            >
              Ajouter un utilisateur
            </v-btn>
          </router-link>
        </div>
      </v-card-text>

      <div class="clear"></div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Nom
              </th>
              <th class="text-center text-uppercase">
                Prénom
              </th>
              <th class="text-center text-uppercase">
                Email
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody v-if="users.length">
            <tr
              v-for="(user, userIndex) in users"
              :key="'u' + userIndex"
            >
              <td class="font-weight-bold">
                {{ user.lastName ? user.lastName : '-' }}
              </td>
              <td class="text-center">
                {{ user.firstName ? user.firstName : '-' }}
              </td>
              <td class="text-center">
                {{ user.email }}
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'userDetails', params: { userId: user.id }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="error"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      @click="openDeleteUserDialog(user)"
                    >
                      <v-icon dark>
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!users.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <DeleteUserDialog
        :key="Date.now()"
        :delete-user-dialog-state="deleteUserDialogState"
        :user="userToDelete"
        @closeDeleteUserDialog="closeDeleteUserDialog($event)"
      ></DeleteUserDialog>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPlay, mdiPen, mdiEye, mdiTrashCan } from '@mdi/js'
import Vue from 'vue'
import DeleteUserDialog from '@/components/Dialog/UserDialog/DeleteUserDialog'
import config from '../../config'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    DeleteUserDialog,
    Loader
  },
  data: () => ({
    users: [],
    userToDelete: null,
    deleteUserDialogState: false,
    loading: false,
    icons: {
      mdiPlay,
      mdiPen,
      mdiEye,
      mdiTrashCan
    }
  }),
  created () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/users', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.users = data
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    openDeleteUserDialog (user) {
      this.deleteUserDialogState = true
      this.userToDelete = user
    },
    closeDeleteUserDialog (deleteState) {
      if (deleteState === true) {
        this.users = this.users.filter(element => element.id !== this.userToDelete.id)
      }

      this.deleteUserDialogState = false
    }
  }
}
</script>
